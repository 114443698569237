import api from '@apiInstance';

export default {
  add(data) {
    return api.post('currencies', data);
  },
  get(id) {
    return api.fetch(`currencies/${id}`);
  },
  async list(params) {
    const data = await api.fetch('/currencies', { params });
    return data;
  },
  update(data) {
    return api.patch('currencies', data);
  },
  delete(id) {
    return api.remove('currencies', id);
  },
};
